<template>
  <div class="reports">
    <h1>Reports Page</h1>
  </div>
</template>

<script>
// @ is an alias to /src
// import WelcomeMessage from "@/components/WelcomeMessage.vue";

export default {
  name: "Reports",
  components: {
    // WelcomeMessage,
  },
};
</script>
